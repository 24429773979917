import { parseISO } from 'date-fns';

export const isActive = definePolicy(() => (user: { status: string }) => user.status === 'active');

export const isVerified = definePolicy(
	() => (user: { emailVerified?: string }) => !!user.emailVerified && parseISO(user.emailVerified) < new Date(),
);

export const isPendingEmailVerification = definePolicy(() => (user: { emailVerified?: string }) => !user.emailVerified);

export const isAppUser = definePolicy(() => all(isActive(), isVerified()));
